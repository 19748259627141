<template>
  <div class="upload-file">
    <!-- list-type="picture-card" -->
    <el-upload
      class="el-upload-box"
      accept=".jpg, .jpeg, .png, .gif"
      ref="upload"
      action="#"
      :show-file-list="false"
      list-type="picture-card"
      :on-change="handleChange"
      :file-list="fileList"
      :auto-upload="false"
    >
      <!-- :http-request="uploadFile" -->
      <div v-if="imageUrl" class="image-avater" :title="commit.titleDete">
        <img :src="imageUrl" class="avatar" />
        <div class="avater-cover">
          <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
          <i class="el-icon-upload"></i>
        </div>
      </div>
      <div v-else>
        <i class="el-icon-upload avatar-uploader-icon"></i>
        <span class="el-upload-tile">{{ commit.titleDete }}</span>
      </div>
    </el-upload>
    <div v-if="commit.msg">请选择</div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    commit: {
      type: Object
      // default: {}
    }
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: ''
    };
  },
  methods: {
    //监控上传文件列表
    handleChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find(f => f.name === file.name);
      if (existFile) {
        this.$message.error('当前文件已经存在!');
        fileList.pop();
      }

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.$emit('beforeAvatarUpload', file, this.commit.paramsName);
        this.imageUrl = file.url;
        this.fileList = [file];
      }
    },
    handlePictureCardPreview() {
      this.dialogVisible = true;
      this.dialogImageUrl = this.fileList[0].url;
    }
  }
};
</script>
<style lang="scss">
.upload-file {
  display: inline-block;
  margin-right: 6px;
  position: relative;
  margin-left: 10px;

  &:hover {
    .avater-cover {
      display: block;
    }
    // i {
    //   color: #01ADBE;
    // }
  }
}

.image-avater {
  i {
    color: #fff;
  }

  i + i {
    margin-left: 20px;
  }

  .avater-cover {
    display: none;
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    height: 150px;
    top: 0;
    line-height: 150px;
  }

  .avatar {
    width: 100%;
    display: block;
  }
}

.el-upload-box {
  & > div {
    height: 150px;
    width: 150px;
    line-height: 30px;
    overflow: hidden;

    i {
      margin-top: 30px;
    }
    .el-upload-tile {
      display: block;
    }
  }
}
</style>
