export const formDesc = {
  refineryName: {
    type: 'input',
    label: '来源炼厂',
    layout: 12,
    rules: [{ required: true, message: '请输入来源炼厂' }]
  },
  oilDepot: {
    type: 'input',
    label: '油库名称',
    layout: 12,
    rules: [{ required: true, message: '请输入油库名称' }]
  },
  driverLicense: {
    type: 'input',
    label: '运输车牌号',
    rules: [{ required: true, message: '请输入运输车牌号' }]
  },
  driverName: {
    type: 'input',
    label: '司机姓名',
    layout: 12,
    rules: [{ required: true, message: '请输入司机姓名' }]
  },
  driverPhone: {
    type: 'input',
    label: '司机手机号',
    layout: 12,
    attrs: {
      maxLength: 11
    },
    rules: [{ required: true, message: '请输入司机手机号' }]
  },
  oilName: {
    type: 'select',
    label: '油品型号',
    layout: 12,
    options: [
      { text: '92#', value: '92#' },
      { text: '95#', value: '95#' },
      { text: '93#', value: '93#' },
      { text: '97#', value: '97#' },
      { text: '98#', value: '98#' },
      { text: '101#', value: '101#' },
      { text: '0#', value: '0#' },
      { text: '5#', value: '5#' },
      { text: '-10#', value: '-10#' },
      { text: '-20#', value: '-20#' },
      { text: '-35#', value: '-35#' },
      { text: '-50#', value: '-50#' }
    ],
    rules: [{ required: true, message: '请选择油品型号' }]
  },
  oilSumT: {
    type: 'input',
    label: '油量(吨)',
    layout: 12,
    rules: [{ required: true, message: '请输入油量' }]
  },
  oilTankNum: {
    type: 'input',
    label: '油罐号',
    rules: [{ required: true, message: '请输入油罐号' }]
  },
  density: {
    type: 'input',
    label: '密度(kg/m³)',
    layout: 12,
    rules: [{ required: true, message: '请输入密度' }]
  },
  completeTime: {
    type: 'datetime',
    label: '卸油时间',
    layout: 12,
    attrs: {
      'value-format': 'yyyy-MM-dd hh:mm:ss'
    },
    rules: [{ required: true, message: '请输入卸油时间' }]
  }
};
