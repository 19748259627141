<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <ele-form
      class="form-wrap"
      v-model="RowInfo"
      :form-desc="formDesc"
      ref="form"
      labelPosition="right"
      :isShowSubmitBtn="true"
      submitBtnText="保存"
      :isShowBackBtn="false"
      :span="16"
      :request-fn="handleSubmit"
      :isShowErrorNotify="false"
    >
      <template v-slot:form-footer>
        <div class="el-col el-col-24">
          <el-form-item label="证件上传">
            <img-upload
              v-for="item in uploads"
              :key="item.id"
              :commit="item"
              @beforeAvatarUpload="beforeAvatarUpload"
            />
          </el-form-item>
        </div>
      </template>
    </ele-form>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import ImgUpload from '@/components/ImgUpload';
import { getStorage } from '@/utils/storage';
import { formDesc } from './columns/OilinReport';
import axios from 'axios';

export default {
  components: {
    PageHeader,
    ImgUpload
  },
  data() {
    return {
      site_id: getStorage('site_id'),
      formData: {
        smart_cycle: '',
        tanker_cycle: ''
      },
      uploads: [
        {
          id: '0',
          titleDete: '上传出油单',
          addAction: '/api/oilStation/uploadTakingImg',
          data: '1',
          paramsName: 'outOrderImg'
        },
        {
          id: '1',
          titleDete: '上传油品质检单',
          addAction: '/api/oilStation/uploadTakingImg',
          data: '2',
          paramsName: 'certImg'
        },
        {
          id: '2',
          titleDete: '上传车辆照片',
          addAction: '/api/oilStation/uploadTakingImg',
          data: '3',
          paramsName: 'carImg'
        }
      ],
      fileList: [],
      formDesc,
      RowInfo: {}
      // fileList: []
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
  },
  methods: {
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      params.siteId = this.site_id;
      let fileData = new FormData(); // new formData对象
      this.fileList.outOrderImg &&
        fileData.append('outOrderImg', this.fileList.outOrderImg.raw); // append增加数据
      this.fileList.certImg &&
        fileData.append('certImg', this.fileList.certImg.raw); // append增加数据
      this.fileList.carImg &&
        fileData.append('carImg', this.fileList.carImg.raw); // append增加数据
      for (let obj in params) {
        fileData.append(obj, params[obj]);
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      axios
        .post('/api/info/oilin/add_form', fileData, config)
        .then(response => {
          if (response.data.code === 0) {
            this.$message({
              message: response.data.msg,
              type: 'success'
            });
            this.$store
              .dispatch('app/setCurrentMenu', '/oilin/oilin_record')
              .then(() => {
                this.$router.push('/oilin/oilin_record');
              });
          } else {
            this.$message({
              message: response.data.msg,
              type: 'error'
            });
          }
        });
    },
    beforeAvatarUpload(file, params_name) {
      this.fileList[params_name] = file;
    }
  }
};
</script>
<style lang="scss" scoped>
.form-wrap {
  margin-top: 50px;
}

.form-item-tips {
  color: #f8150d;
  font-size: 12px;
}
</style>
