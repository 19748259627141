import { render, staticRenderFns } from "./OilinReport.vue?vue&type=template&id=037dbf88&scoped=true"
import script from "./OilinReport.vue?vue&type=script&lang=js"
export * from "./OilinReport.vue?vue&type=script&lang=js"
import style0 from "./OilinReport.vue?vue&type=style&index=0&id=037dbf88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037dbf88",
  null
  
)

export default component.exports